import React, {useState, useEffect, useRef} from "react"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    FormFeedback
  } from "reactstrap";
  import Select from 'react-select';
  import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import Loader from "components/Common/Loader";
import { executeQuery } from "store/actions";

const SmartIntakeMain = () => {

//meta title
const local_store_name = 'chatMessages'

document.title = "Smart Intake | Smarteeva";

const dispatch = useDispatch();
  // Create a reference to the chat window
  const chatWindowRef = useRef(null);


const [familyTypeOptions, setFamilyTypeOptions] = useState([
  {"label": "Ambulatory Infusion Pumps", "value": "Ambulatory Infusion Pumps"},
  {"label": "Ambulatory Infusion Pumps Non-Serialized", "value": "Ambulatory Infusion Pumps Non-Serialized"},
  {"label": "Ambulatory Infusion Pumps-Duodopa", "value": "Ambulatory Infusion Pumps-Duodopa"},
  {"label": "Breathing", "value": "Breathing"},
  {"label": "Bronchial Hygiene and Lung Expansion", "value": "Bronchial Hygiene and Lung Expansion"},
  {"label": "Convective Warming Serialized", "value": "Convective Warming Serialized"},
  {"label": "Fluid Warming Non-Serialized", "value": "Fluid Warming Non-Serialized"},
  {"label": "Fluid Warming Serialized", "value": "Fluid Warming Serialized"},
  {"label": "Implantable Ports", "value": "Implantable Ports"},
  {"label": "Infusion Extension Sets and Disposables", "value": "Infusion Extension Sets and Disposables"},
  {"label": "Intubation", "value": "Intubation"},
  {"label": "Pain Management", "value": "Pain Management"},
  {"label": "Patient Monitoring Serialized", "value": "Patient Monitoring Serialized"},
  {"label": "Peripheral Intravenous Catheters (PIVC)", "value": "Peripheral Intravenous Catheters (PIVC)"},
  {"label": "Pressure Monitoring", "value": "Pressure Monitoring"},
  {"label": "Sharps Safety", "value": "Sharps Safety"},
  {"label": "Subcutaneous Infusion Systems", "value": "Subcutaneous Infusion Systems"},
  {"label": "Syringe Infusion Pumps", "value": "Syringe Infusion Pumps"},
  {"label": "Tracheostomy", "value": "Tracheostomy"},
  {"label": "Ventilators Serialized", "value": "Ventilators Serialized"},
  {"label": "Ambulatory Infusion Pumps-Duodopa Non-Serialized", "value": "Ambulatory Infusion Pumps-Duodopa Non-Serialized"},
  {"label": "Convective Warming Non-Serialized", "value": "Convective Warming Non-Serialized"},
  {"label": "Drainage", "value": "Drainage"},
  {"label": "Patient Monitoring Non-Serialized", "value": "Patient Monitoring Non-Serialized"},
  {"label": "Pressure Monitoring", "value": "Pressure Monitoring"},
  {"label": "Suction", "value": "Suction"},
  {"label": "Temperature Probes", "value": "Temperature Probes"},
  {"label": "Unknown Disposable Device LSM", "value": "Unknown Disposable Device LSM"},
  {"label": "Veterinary Serialized", "value": "Veterinary Serialized"},
  {"label": "Veterinary Non-Serialized", "value": "Veterinary Non-Serialized"},

])

const [dictionaryFields, setDictionaryFields] = useState([])
const [isLoading, setIsLoading] = useState(false);
const [dictionaryError, setDictionaryError] = useState('')
const [queryResult, setQueryResult] = useState('')

const [messages, setMessages] = useState(() => {
  // Load messages from localStorage on page load
  const savedMessages = localStorage.getItem(local_store_name);
  return savedMessages ? JSON.parse(savedMessages) : [];
});

const { error, query_result, loading } = useSelector(state => ({
  error: state.tasks.error,
  loading: state.tasks.loading,
  query_result: state.tasks.query_result,
}));

useEffect(() => {
  setIsLoading(loading)
}, [loading]);

useEffect(() => {
  if (query_result) {
    // Update the chat messages when query result is available
    const newMessages = JSON.parse(localStorage.getItem(local_store_name))
    setMessages(newMessages);
    // console.log(newMessages)
  }
}, [query_result]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
        event_desc: '',
        family : '',
    },
    validationSchema: Yup.object({
      event_desc: Yup.string().required("Please enter the event description.")
    }),
    onSubmit: (values, {resetForm}) => {
      // console.log(values)
      let newMessages = []
      if(messages && messages.length > 0){
         newMessages = [
          ...messages,
          { user: "me", text: 'Event Description: '+values.event_desc+'. Family: '+values.family }, 
        ];
      }else{
         newMessages = [
          { user: "me", text: 'Event Description: '+values.event_desc+'. Family: '+values.family }, 
        ];
      }
      setMessages(newMessages);

      // Save to localStorage
      localStorage.setItem(local_store_name, JSON.stringify(newMessages));
      
        let final_data = {
          prompt_text : 'Event Description: '+values.event_desc+'. Family: '+values.family,
        }
        dispatch(executeQuery(final_data))
      resetForm()
    },
});

// Scroll to bottom when messages update
useEffect(() => {
  if (chatWindowRef.current) {
    chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
  }
}, [messages]);

const prettyPrintJson = (json) => {
  const jsonString = JSON.stringify(json, null, 2);
  const formattedJson = jsonString.replace(/"([^"]+)":/g, '<span class="json-key">$1:</span>')
                                  .replace(/:\s"([^"]+)"/g, ': <span class="json-value">"$1"</span>')
                                  .replace(/:\s([^,]+),?/g, ': <span class="json-value">$1</span>');
  return formattedJson.split('\n').map((item, index) => (
    <div key={index} dangerouslySetInnerHTML={{ __html: item }} />
  ));
};

  return (
    <React.Fragment>
      <div className="page-content page-centered">
        <Container fluid={true}>
          {/* <Breadcrumbs title="Smart Intake" breadcrumbItem="Smart Intake" /> */}
          <CardTitle>ICU Medical Bot - V2</CardTitle>
                  {/* <p className="card-title-desc">
                    Subtitle placeholder</p> */}
          <Row>
            <Col lg="12">

            <Card>
                <CardBody>
              
                  <Form  onSubmit={(e)=>{
                    e.preventDefault()
                  }}>
                
                  <CardTitle >Result</CardTitle>
                  <p className="card-title-desc">
                    The responses will be displayed on the box below</p>

                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                        <div className="scrollable-div"  ref={chatWindowRef}>
                      
                        <div className="chat-window">
                      {messages && messages.map((message, index) => (
                        <div key={index} className={`message ${message.user}`}>
                          <div className="message-text">{message.text}</div>
                        </div>
                      ))}
                    </div>
                        
                          
                        </div>

                      
                        </div>
                      </Col>
                  
                    </Row>

                
                  </Form>
                </CardBody>
              </Card>


            <Card>
                <CardBody>
                  {/* <CardTitle >Prompt Fields</CardTitle> */}
                 

                  <Form onSubmit={(e)=>{
                    e.preventDefault()
                  }}>
                 
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input" className="required">Event Description</Label>
                          <Input
                          rows={5}
                            type="textarea"
                                    className="form-control"
                                    placeholder="Enter the event description"
                                    name="event_desc"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.event_desc || ""}
                                    invalid={
                                      validation.touched.event_desc && validation.errors.event_desc ? true : false
                                    }
                                  />
                                  {validation.touched.event_desc && validation.errors.event_desc ? (
                                    <FormFeedback type="invalid">{validation.errors.event_desc}</FormFeedback>
                                  ) : null}
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Family</Label>
                          {/* <Input
                            type="text"
                                    className="form-control"
                                    placeholder="Enter family category"
                                    name="family"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.family || ""}
                                    invalid={
                                      validation.touched.family && validation.errors.family ? true : false
                                    }
                                  /> */}

                                  <Select
                                  isClearable
                                  isSearchable
                                  name={'family'}
                        
                                  onChange={(e)=>{
                                    if(e){
                                      validation.setFieldValue('family', e.value)
                                      validation.setFieldError('family',false)
                                    }else{
                                      validation.setFieldValue('family', '')
                                      validation.setFieldError('family',true)
                                    }
                                  }}
                                  onBlur={validation.handleBlur}
                                  options={familyTypeOptions}
                                  value={validation.values.family == '' ? '' :{label : validation.values.family, value : validation.values.family}}
                              
                                className={validation.touched.family && validation.errors.family ? 'invalid-select' : ''}
                             
                                />

                                  {validation.touched.family && validation.errors.family ? (
                                    <FormFeedback type="invalid">{validation.errors.family}</FormFeedback>
                                  ) : null}
                         
                        </div>
                        <div>
                      <button type="submit" className="btn btn-primary w-md" style={{float : 'right'}} onClick={()=>{
                        validation.handleSubmit()
                      }}>
                        Submit
                      </button>
                    </div>
                      </Col>
                  
                    </Row>

                  </Form>
                </CardBody>
              </Card>

        
              <Card>
                <CardBody>
                     
                    <div>
                      <button type="submit" className="btn btn-primary w-md" style={{float : 'right'}} onClick={()=>{
                          localStorage.removeItem(local_store_name)
                          setMessages([])
                          validation.resetForm()
                      }}>
                        Reset
                      </button>
                    </div>
                 
                </CardBody>
              </Card>
              
          
                </Col>
                </Row>
                </Container>
                </div>
                <Loader isLoading={isLoading} />
                </React.Fragment>
  )
}

export default SmartIntakeMain
