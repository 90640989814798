import { EXECUTE_QUERY_FAIL, EXECUTE_QUERY_SUCCESS, SET_LOADING_INDICATOR_SMART_INTAKE } from "./actionTypes"

const INIT_STATE = {
  query_result: [],
  error: {},
  loading : false
}

const tasks = (state = INIT_STATE, action) => {
  switch (action.type) {
    case EXECUTE_QUERY_SUCCESS:
      return {
        ...state,
        query_result: action.payload,
        loading : false
      }

    case EXECUTE_QUERY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading : false
      }

      case SET_LOADING_INDICATOR_SMART_INTAKE:
        return {
            ...state,
            loading: action.payload
        };


    default:
      return state
  }
}

export default tasks
