import { EXECUTE_QUERY, EXECUTE_QUERY_FAIL, EXECUTE_QUERY_SUCCESS, SET_LOADING_INDICATOR_SMART_INTAKE} from "./actionTypes"

export const executeQuery = (data) => ({
  type: EXECUTE_QUERY,
  payload : {data}
})

export const executeQuerySuccess = query_result => ({
  type: EXECUTE_QUERY_SUCCESS,
  payload: query_result,
})

export const executeQueryFail = error => ({
  type: EXECUTE_QUERY_FAIL,
  payload: error,
})


export const setLoadingIndicatorSmartIntake = (loading) => {
  // console.log(loading)
  return {
    type: SET_LOADING_INDICATOR_SMART_INTAKE,
    payload: loading,
  };
};